export default {
    data() {
        return {
            config: this.$store.state.config,
            isPhone:this.$store.state.isphone
        }
    },
    watch: {
        setconfig(e) {
            this.config = e
        },
        isphone(val) {
            this.isPhone = val
          },
    },
    computed: {
        setconfig() {
            return this.$store.state.config
        },
        isphone() {
            return this.$store.state.isphone
          }
    },
}
