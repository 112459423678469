<template>
      <div class="footer">
      <img class="footer__img" :src="config.bottom_logo" alt="" />
      <p class="footer__info">{{config.record}}</p>
    </div>
</template>

<script>
import config from '../mixin/config'
export default {
  mixins:[config],
data(){
  return{
  }
}
}
</script>

<style lang="scss" scoped>
@media screen and (min-width:769px) {
  .footer {
  width: 100%;
  height: 240px;
  background: #70553C;
  border-radius: 3px;
  padding: 29px 0 51px 0;
  box-sizing: border-box;

  &__img {
    width: 83px;
    height: 119px;
    margin-bottom: 27px;
    object-fit: cover;
  }

  &__info {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
}
}
@media screen and (max-width:768px) {
  .footer {
  width: 100%;
  height: 240px;
  background: #70553C;
  border-radius: 3px;
  padding: 39px 0 42px 0;
  box-sizing: border-box;

  &__img {
    width: 83px;
    height: 119px;
    margin-bottom: 20px;
    object-fit: cover;
  }

  &__info {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0;
  }
}
}

</style>