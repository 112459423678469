<template>
  <div class="wrapper">
    <div class="header">
      <img class="header__logo" src="@/assets/image/home/logo.png" alt="" />
      <div class="model">
       <img @click="call" class="model__call" src="@/assets/image/home/tel.png" alt="" />
        <img class="model__list" src="@/assets/image/home/list.png" alt="" @click="changeListState" />
      </div>
      <van-popup v-if="isPhone" position="top" v-model="show">
        <div class="header__box">
          <ul class="header__nav">
            <li v-for="(item, indexs) in navlist" :key="indexs" @click="$router.push({ path: item.path })" ref="navitem"
              :class="{ 'header__nav-item': true, 'phone-active': $route.fullPath === item.path }">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </van-popup>
      <div v-if="!isPhone" class="header__box">
        <ul class="header__nav">
          <li v-for="(item, indexs) in navlist" :key="indexs" ref="navitem" 
            @click="() => { $router.push({ path: item.path }); index = indexs + 1 }" 
            :class="{ 'header__nav-item': true, active: Apathindex===indexs || $route.fullPath === item.path }">
            {{ item.name }}
          </li>
          <li class="nav_active" :style="move"></li>
        </ul>
        <div class="phone">
          <img class="phone__img" src="@/assets/phone.png" alt="">
          <span class="phone__text">{{config.phone}}</span>
        </div>
      </div>
    </div>
    <!-- <div class="mask" v-show="showMask" @click="changeListState"></div> -->
  </div>
</template>

<script>
import config from '@/mixin/config.js'
export default {
  props:{
    Apathindex:{
      default:null
    }
  },
  mixins:[config],
  data() {
    return {
      index: 1,
      show: false,
      move: " transform: translateX(-23px);",
      listState: false,
      showMask: false,
      isPhone: this.$store.state.isphone,
      navlist: [
        {
          path: '/',
          name: '关于和锦堂'
        },
        {
          path: '/Information',
          name: '和锦动态'
        },
        {
          path: '/HejinWelfare',
          name: '和锦公益'
        },
        {
          path: '/HejinSchool',
          name: '和锦学堂'
        },
        {
          path: '/RD',
          name: '研发中心'
        },

      ]
    };
  },
  watch: {
    isphone(val) {
      this.isPhone = val
    },
  },
  computed: {
    isphone() {
      return this.$store.state.isphone
    }
  },
  mounted() {
    this.info();
    window.onresize = () => {
      return setTimeout(()=>{
        this.$nextTick(()=>{
          this.info();
        })
      },1000) 
    };
  },
  methods: {
    info() {
      try {
        let index = this.Apathindex+1;
        if(!this.Apathindex){
          for (let v in this.navlist) {
          if (this.$route.fullPath === this.navlist[v].path) {
            index = +v + 1
            break
          }
        }
        }
        if (index === 1) return;
        let domobj = this.$refs.navitem[0].offsetWidth;
        let data = domobj * (index - 1) - 21;
        this.move = `transform:translateX(${data}px)`;
      } catch (e) {
        return e;
      }
    },
    changeListState() {
      this.show = !this.show;
    },
    call(){
      window.location=`tel:${this.config.phone}`
    }
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  .header {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;

    &__box {
      display: flex;
      justify-content: flex-start;
      margin-left: 153px;

      .phone {
        margin-left: 174px;
        display: flex;
        align-items: center;

        &__img {
          height: 31px;
          width: 31px;
        }

        &__text {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #CA9430;
          line-height: 13px;
          display: inline-block;
          margin-left: 8px;
        }
      }
    }

    &__logo {
      width: 194px;
      height: 62px;
      margin-left: 270px;
    }

    &__nav {
      display: flex;
      position: relative;
     
      &-item {
        list-style-type: none;
        width: 145px;
        height: 59px;
        line-height: 59px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000;
        background-size: 100%;
        z-index: 2;
        cursor: pointer;
      }

      &-item:hover {
        color: rgb(107, 102, 65);
      }

      .nav_active {
        width: 204px;
        height: 59px;
        background-size: 100%;
        position: absolute;
        background-image: url("../assets/image/home/navback.png");
        color: #ffffff;
        z-index: 1;
      }

      .active {
        color: #ffffff;
      }
    }
  }

  .model {
    display: none;

  }
}

@media screen and (max-width: 768px) {
  ::v-deep {
    .van-popup {
      background-color: rgba(0, 0, 0, 0);
    }

    .van-overlay {
      margin-top: 90px;
    }
  }

  .header {
    position: relative;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    padding: 21px 30px 21px 40px;
    box-sizing: border-box;
    background-color: #fefae9;
    z-index: 3;

    &__logo {
      width: 152px;
      height: 48px;
      z-index: 2033;
    }

    &__nav {
      margin-top: 90px;
      background-color: #fefae9;
      width: 750px;

      &-item {
        padding: 22px 0;
        list-style-type: none;
        width: 270px;
        height: 90px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000;
        background-size: 100%;
        z-index: 2;
        cursor: pointer;
        margin: auto;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 90px;
      }

      &-item:hover {
        color: rgb(107, 102, 65);
      }


      .phone-active {
        background-image: url("../assets/image/home/navback.png");
        color: #ffffff;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-y: 50%;
      }

      .active {
        color: #ffffff;
      }
    }
  }

  .model {
    display: block;
    margin-left: auto;
    z-index: 2033;
    display:  flex;
    align-items: center;
    &__call {
     
      width: 38px;
      height: 38px;
    }

    &__list {
      margin-left: 20px;
      width: 38px;
      height: 38px;
    }
  }

  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
}
</style>
