<template>
  <div class="home">
    <nav-vue></nav-vue>
    <div class="banner">
      <el-carousel arrow="always" style="height:100%">
        <el-carousel-item v-for="( item, index) in config.home_lunbo" :key="index">
          <a :href="config.home_lunbo_link[index + 1]" target="_blank">
            <img class="banner__img" :src="item" alt="" />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <img class="content__bg" src="@/assets/image/home/greenleaf.png" alt="">
      <img class="content__cloud" src="@/assets/image/home/cloud.png" alt="" />
      <div class="Culture">
        <div class="Culture__title">
          <p class="__p">品牌文化</p>
        </div>
        <div class="Culture__information">
          <img class="Culture__information__img" :src="config.home_center_banner" alt="">
          <div class="Culture__information__info">
            <p>
              {{ config.brand_content }}
            </p>
          </div>
        </div>
      </div>
      <img class="fiveBanner" src="@/assets/image/home/fiveBanner.png" alt="">
      <div class="bg">
        <div class="introduce">
          <div class="introduce__title">
            <p class="__p">医师介绍</p>
          </div>
          <!-- pc轮播图 -->
          <el-carousel v-if="!isphone" @change="changeintroduce" ref="introduce" indicator-position="none"
            arrow="never">
            <el-carousel-item v-for="(item, index) in doctorData" :key="index" class="introduce__information">
              <div class="introduce__information__img">
                <img :src="$url + item.image" alt="">
              </div>
              <div class="introduce__information__info">
                <div class="introduce__information__info__title">
                  <span class="introduce__information__info__title-name">{{ item.name }}</span>
                  <span class="introduce__information__info__title-class"> {{ item.job }}</span>
                </div>
                <div class="introduce__information__info__introduce">
                  <div class="introduce__information__info__introduce__title">
                    医师介绍
                  </div>
                  <p>
                    {{ item.introduce }}
                  </p>
                </div>
                <!-- <div class="introduce__information__info__beGoodat">
                  <div class="introduce__information__info__beGoodat__title">
                    擅长治疗
                  </div>
                  <p>
                    {{ item.technology }}
                  </p>
                </div> -->
              </div>
            </el-carousel-item>
          </el-carousel>
          <!-- 手机轮播图 -->
          <el-carousel v-else @change="changeintroduce" ref="introduce" indicator-position="none" :autoplay="true"
            arrow="never">
            <el-carousel-item v-for="(item, index) in doctorData" :key="index" class="introduce__information">
              <div class="introduce__information__img">
                <img :src="$url + item.image" alt="">
              </div>
              <div class="introduce__information__info">
                <div class="introduce__information__info__title">
                  <span class="introduce__information__info__title-name">{{ item.name }}</span>
                  <span class="introduce__information__info__title-class"> {{ item.job }}</span>
                </div>
                <div class="introduce__information__info__introduce">
                  <div class="introduce__information__info__introduce__title">
                    医师介绍
                  </div>
                  <p>
                    {{ item.introduce }}
                  </p>
                </div>
                <!-- <div class="introduce__information__info__beGoodat">
                  <div class="introduce__information__info__beGoodat__title">
                    擅长治疗
                  </div>
                  <p>
                    {{ item.technology }}
                  </p>
                </div> -->
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="introduce__Carousel">
            <img @click="$refs.introduce.prev()" class="introduce__Carousel__left" src="@/assets/image/home/lefts.png"
              alt="">
            <div class="introduce__Carousel__box">
              <div @click="setintroduceIndex(index)" v-for="(item, index) in doctorData" :key="index"
                :class="{ 'introduce__Carousel__boxitem': true, 'CarouselActive': doctorIndexs === index }">
                <img class="introduce__Carousel__boxitem-img" :src="$url + item.image" alt="" />
              </div>
            </div>
            <img @click="$refs.introduce.next()" class="introduce__Carousel__right" src="@/assets/image/home/rights.png"
              alt="">
          </div>
        </div>
        <div class="course">
          <div class="course__title">
            <p class="__p">发展历程</p>
          </div>
          <!-- pc轮播图 -->
          <div v-if="!isphone" class="course__carousel">
            <div class="course__carousel__left">
              <img @click="onNext('up')" class="course__carousel__left_top" src="@/assets/image/home/top.png" alt="">
              <div class="course__carousel__left_box">
                <div @click="setCourseIndex(index)" v-for="(item, index) in courseData" :key="index"
                  :class="{ 'course__carousel__left_box-item': true, 'carouselActive': courseActive === index }">
                  {{ item.time }}
                </div>
              </div>
              <img @click="onNext('down')" class="course__carousel__left_bottom" src="@/assets/image/home/bottom.png"
                alt="">
            </div>
            <div class="course__carousel__right">
              <el-carousel @change="changeCarousel" ref="carousel" direction="vertical" :autoplay="false">
                <el-carousel-item v-for="(item, index) in courseData" :key="index">
                  <img class="course__carousel__right__img" :src="$url + item.image" alt="">
                  <div>
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.content }}</p>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <!-- 手机轮播图 -->
          <div v-else class="course__carousel">
            <!-- <div class="course__carousel__left">
              <img @click="onNext('up')" class="course__carousel__left_top" src="@/assets/image/home/top.png" alt="">
              <div class="course__carousel__left_box">
                <div @click="setCourseIndex(index)" v-for="(item, index) in courseData" :key="index"
                  :class="{ 'course__carousel__left_box-item': true, 'carouselActive': courseActive === index }">
                  {{ item.time }}
                </div>
              </div>
              <img @click="onNext('down')" class="course__carousel__left_bottom" src="@/assets/image/home/bottom.png"
                alt="">
            </div> -->
            <el-carousel indicator-position="none" type="card" @change="changeCarousel" ref="carousel" arrow="always"
              :autoplay="false">
              <el-carousel-item v-for="(item, index) in courseData" :key="index">
                <div class="course__carouselbox">
                  <img class="course__carouselbox__img" :src="$url + item.image" alt="" />
                  <div class="course__carouselbox__time">{{ item.time }}</div>
                </div>
                <div class="course__carousel__content">
                  <h2>{{ item.title }}</h2>
                  <p>{{ item.content }}</p>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="framework">
          <img class="framework__img" :src="config.framework_banner" alt="">
        </div>
        <div class="cooperation">
          <div class="cooperation__title">
            <p class="__p">战略合作</p>
          </div>
          <div class="cooperation__content">
            <div class="cooperation__content-item" v-for="(item, index) in cooperationData" :key="index">
              <h2>{{ item.title }}</h2>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="contact">
        <span class="contact_line"></span>
        <div class="contact__box">
          <div class="contact__box__phone">
            <div><img src="@/assets/image/home/phone.png" alt="" />
              客服电话
            </div>
            <span>{{ config.phone }}</span>
          </div>
          <!-- <div class="contact__box__mail">
            <div><img src="@/assets/image/home/mail.png" alt="" />
              邮箱地址
            </div>
            <span>{{ config.email }}</span>
          </div> -->
          <div class="contact__box__from">
            <div><img src="@/assets/image/home/from.png" alt="" />
              办公地址
            </div>
            <span v-html="setFrom(config.address)"></span>
          </div>
        </div>
        <span class="contact_line"></span>
      </div>
    </div>
    <footer-vue></footer-vue>
  </div>
</template>

<script>
import NavVue from '../components/nav.vue'
import FooterVue from '../components/footer.vue'
import { courseIndex, cooperationIndex, doctorIndex } from '@/uilt/api.js'
import config from '../mixin/config'
export default {
  name: 'HomeView',
  components: { NavVue, FooterVue },
  data() {
    return {
      courseActive: 0,
      courseData: [],
      cooperationData: [],
      doctorData: [],
      doctorIndexs: 0,
    }
  },
  mixins: [config],
  created() {
    this.info()
  },

  methods: {
    setFrom(e) {
      if (!e) return;
      return e.replace('  ', '</br>')
    },
    info() {
      this.getCourseIndex()
      this.getCooperationIndex()
      this.getdoctorIndex()
    },
    onNext(e) {
      if (e === 'up') {
        this.$refs.carousel.prev()
        return;
      }
      if (e === 'down') {
        this.$refs.carousel.next()
      }
    },
    changeCarousel(e) {
      this.courseActive = e
    },
    setCourseIndex(e) {
      this.$refs.carousel.setActiveItem(e)
    },
    getCourseIndex() {
      courseIndex().then(res => {
        this.courseData = res
      })
    },

    getCooperationIndex() {
      cooperationIndex().then(res => {
        this.cooperationData = res
      })
    },
    getdoctorIndex() {
      doctorIndex().then(res => {
        this.doctorData = res
      })
    },
    setintroduceIndex(e) {
      this.$refs.introduce.setActiveItem(e)
    },
    changeintroduce(e) {
      this.doctorIndexs = e
    }
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width:769px) {
  .home {
    width: 100%;
  }

  .banner {
    width: 100%;
    height: 620px;

    ::v-deep {
      .el-carousel__container {
        height: 100%;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    width: 100%;
    position: relative;

    &__bg {
      position: absolute;
      width: 100%;
      left: 0;
      top: 271px;
      pointer-events: none;
      z-index: 1;
    }

    &__cloud {
      position: absolute;
      left: 0;
      top: -100px;
      height: 482px;
      width: 406px;
    }

    .Culture {
      position: relative;
      height: 800px;
      width: 100%;

      &__title {
        margin-top: 32px;
        background-image: url('../assets/image/home/title.png');
        width: 203px;
        height: 140px;
        background-size: 100%;
        line-height: 140px;
        font-size: 32px;
        font-family: 'FZLiShu-S01Ss';
        font-weight: bold;
        color: #FFFFFF;
        margin: auto;
      }

      &__information {
        display: flex;
        justify-content: center;
        margin-top: 63px;
        align-items: center;

        &__img {
          width: 590px;
          height: 332px;
          object-fit: cover;
          transform: translateX(83px);
          z-index: 2;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &__info {
          width: 800px;
          height: 434px;
          background-image: url('../assets/image/home/CultureInfo.png');
          background-size: 100%;
          position: relative;
          overflow: hidden;
          z-index: 1;

          p {

            position: absolute;
            margin: 0;
            padding: 75px 69px 0 119px;
            text-align: left;
            font-size: 18px;
            font-family: Songti SC;
            font-weight: 400;
            color: #221E1F;
            line-height: 32px;
          }
        }
      }

    }

    .fiveBanner {
      width: 100%;
      object-fit: cover;
      height: 419px;
    }

    .bg {
      background-image: url('../assets/image/home/bg.png');
      background-size: 100% 2930px;
    }

    .introduce {

      &__title {
        margin-top: 58px;
        background-image: url('../assets/image/home/title.png');
        width: 203px;
        height: 140px;
        background-size: 100%;
        line-height: 140px;
        font-size: 32px;
        font-family: 'FZLiShu-S01Ss';
        font-weight: bold;
        color: #FFFFFF;
        margin: auto;
      }

      ::v-deep {
        .el-carousel {


          height: 457px;
          overflow: hidden;
        }

        .el-carousel__container {
          width: 1300px;
          height: 100%;
          margin: auto;
        }
      }

      &__information {
        display: flex;
        justify-content: center;

        &__img {
          width: 330px;
          height: 457px;
          background-size: 100%;
          background-repeat: no-repeatß;
          position: relative;

          img {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

        }

        &__info {
          margin-left: 99px;
          width: 871px;
          margin-top: 25px;

          &__title {
            width: 871px;
            border-bottom: 1px solid #C8934E;
            text-align: left;
            padding-bottom: 10px;

            &-name {
              font-size: 36px;
              font-family: Songti SC;
              font-weight: 900;
              color: #333333;
              display: inline-block;
            }

            &-class {
              font-size: 24px;
              font-family: Songti SC;
              font-weight: 400;
              color: #666666;
              margin-left: 23px;
              display: inline-block;
            }
          }

          &__introduce {
            margin-top: 14px;

            &__title {
              width: 126px;
              height: 44px;
              background-image: url('../assets/image/home/3.png');
              background-size: 100%;
              line-height: 44px;
              font-size: 24px;
              font-family: Songti SC;
              font-weight: 400;
              color: #FFFFFF;
            }

            p {
              margin: 21px 0 0 0;
              padding: 0;
              font-size: 16px;
              font-family: Songti SC;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
              text-align: left;
            }
          }

          &__beGoodat {
            margin-top: 54px;

            &__title {
              width: 126px;
              height: 44px;
              background-image: url('../assets/image/home/3.png');
              background-size: 100%;
              line-height: 44px;
              font-size: 24px;
              font-family: Songti SC;
              font-weight: 400;
              color: #FFFFFF;
            }

            p {
              margin: 21px 0 0 0;
              padding: 0;
              font-size: 16px;
              font-family: Songti SC;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
              text-align: left;
            }
          }

        }
      }

      &__Carousel {
        display: flex;
        justify-content: center;
        align-items: center;

        &__left {
          width: 32px;
          height: 58px;
          cursor: pointer;
        }

        &__right {
          width: 32px;
          height: 58px;
          cursor: pointer;
        }

        &__box {
          margin: 61px 41px;
          display: flex;

          &item {
            width: 174px;
            height: 166px;
            position: relative;
            background-image: url('../assets/image/home/2.png');
            background-size: 100%;
            position: relative;
            cursor: pointer;

            &-img {
              -webkit-mask: no-repeat center / contain;
              mask: no-repeat center / contain;
              -webkit-mask-image: url('../assets/image/home/1.png');
              mask-image: url('../assets/image/home/1.png');
              width: 100%;
              height: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              object-fit: cover;
              transform: translate(-50%, -50%);
            }
          }

          .CarouselActive {
            background-image: url('../assets/image/home/1.png');
            background-size: 100%;
          }
        }
      }
    }

    .course {
      background-color: rgba(172, 151, 115, 0.1);

      &__title {
        margin-top: 19px;
        background-image: url('../assets/image/home/title.png');
        width: 203px;
        height: 140px;
        background-size: 100%;
        line-height: 140px;
        font-size: 32px;
        font-family: 'FZLiShu-S01Ss';
        font-weight: bold;
        color: #FFFFFF;
        margin: auto;
      }

      &__carousel {
        display: flex;
        justify-content: center;
        align-items: center;

        &__left {
          &_top {
            width: 63px;
            height: 34px;
            margin-top: 17px;
            cursor: pointer;
          }

          &_bottom {
            width: 63px;
            height: 34px;
            margin-bottom: 56px;
            cursor: pointer;
          }

          &_box {
            width: 250px;

            &-item {
              font-size: 36px;
              font-family: FZLiShu-S01Ss;
              font-weight: bold;
              color: #C8934E;
              margin: 48px 0;
              cursor: pointer;
            }

            &-item:first-child {
              margin-top: 51px;
              margin-bottom: 58px;
            }

            &-item:last-child {
              margin-top: 58px;
              margin-bottom: 72px;
            }

            .carouselActive {
              color: #531409;
            }
          }
        }

        ::v-deep {
          .el-carousel__container {
            height: 600px;
          }
        }

        &__right {
          margin-left: 141px;
          width: 956px;

          &__img {
            width: 100%;
            height: 311px;
            object-fit: cover;
          }

          div {
            text-align: left;
            width: 100%;
            padding: 0 16px 0 13px;
            box-sizing: border-box;

            h2 {
              font-size: 28px;
              font-family: Songti SC;
              font-weight: bold;
              color: #221C16;
            }

            p {
              margin: 0;
              padding: 0;
              font-size: 16px;
              font-family: Songti SC;
              font-weight: 400;
              color: #221C16;
            }
          }

        }
      }
    }

    .cooperation {
      background-image: url('../assets/image/home/CultureImg.png');
      background-size: 100%;
      background-position: top;
      padding-bottom: 64px;

      &__title {
        background-image: url('../assets/image/home/title.png');
        width: 203px;
        height: 140px;
        background-size: 100%;
        line-height: 140px;
        font-size: 32px;
        font-family: 'FZLiShu-S01Ss';
        font-weight: bold;
        color: #FFFFFF;
        margin: auto;
      }

      &__content {
        margin-top: 27px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-item {
          background-image: url('../assets/image/home/cooperationItem.png');
          background-size: 100%;
          width: 740px;
          height: 540px;
          overflow: hidden;
          margin-right: 100px;
          padding: 80px 100px 0 100px;
          box-sizing: border-box;

          h2 {
            font-size: 20px;
            font-family: Songti SC;
            font-weight: 400;
            color: #000000;
          }

          p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-family: Songti SC;
            font-weight: 400;
            color: #333333;
          }
        }

        &-item:last-child {
          margin-right: 0;
        }
      }
    }


  }

  .framework {
    height: auto;
    width: 100%;
    position: relative;

    &__img {
      margin: 109px auto;
      width: 1131px;
      height: auto;
      object-fit: cover;
    }
  }

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 105px 0 114px;
    box-sizing: border-box;

    &_line {
      flex: 1;
      height: 2px;
      background: #C8934E;
      border-radius: 1px;
      display: block;
    }

    &__box {
      &__phone {
        img {
          width: 22px;
          height: 22px;
          vertical-align: bottom;
        }

        font-size: 18px;
        font-family: Songti SC;
        font-weight: 400;
        color: #32363C;
        line-height: 22px;

        span {
          margin-top: 6px;
          font-size: 18px;
          font-family: Songti SC;
          font-weight: 400;
          color: #32363C;
          display: block;
          line-height: 13px;
        }
      }

      &__mail {
        margin-top: 45px;

        img {
          width: 22px;
          height: 22px;
          vertical-align: bottom;
        }

        font-size: 18px;
        font-family: Songti SC;
        font-weight: 400;
        color: #32363C;
        line-height: 22px;

        span {
          margin-top: 6px;
          font-size: 18px;
          font-family: Songti SC;
          font-weight: 400;
          color: #32363C;
          display: block;
          line-height: 13px;
        }
      }

      &__from {
        margin-top: 45px;

        img {
          width: 22px;
          height: 22px;
          vertical-align: bottom;
        }

        font-size: 18px;
        font-family: Songti SC;
        font-weight: 400;
        color: #32363C;

        span {
          margin-top: 6px;
          font-size: 18px;
          font-family: Songti SC;
          font-weight: 400;
          color: #32363C;
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width:768px) {
  .home {
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    width: 100%;
  }

  .__p {
    display: none;
  }

  .banner {
    width: 100%;
    height: 320px;

    ::v-deep {
      .el-carousel__container {
        height: 100%;
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    width: 100%;
    position: relative;

    &__bg {
      position: absolute;
      width: 100%;
      left: 0;
      top: 78px;
      pointer-events: none;
      z-index: 1;
    }

    &__cloud {
      position: absolute;
      left: -0px;
      top: -100px;
      width: 244px;
      height: 326px;
      opacity: 0.27;
    }

    .Culture {
      position: relative;
      width: 100%;
      margin-bottom: 56px;

      &__title {
        margin: 40px auto 24px auto;
        background-image: url('../assets/image/home/标题.png');
        width: 203px;
        height: 70px;
        background-size: 100%;
        line-height: 70px;
      }

      &__information {
        display: flex;
        justify-content: center;
        align-items: center;

        &__img {
          display: none;
        }

        &__info {
          width: 690px;
          height: 436px;
          background-image: url('../assets/image/home/CultureInfo.png');
          background-size: 100% 100%;
          position: relative;
          overflow: hidden;
          z-index: 1;

          p {
            position: absolute;
            margin: 0;
            padding: 43px 51px 47px 45px;
            text-align: left;
            font-size: 22px;
            font-family: Songti SC;
            font-weight: 400;
            color: #221E1F;
            line-height: 32px;
          }
        }
      }
    }
  }

  .fiveBanner {
    width: 100%;
    object-fit: cover;
    height: 235px;
  }

  .bg {
    background-image: url('../assets/image/home/bg.png');
    background-size: 100% 2930px;
  }

  .introduce {

    &__title {
      margin: 40px auto 24px auto;
      background-image: url('../assets/image/home/组39.png');
      width: 195px;
      height: 70px;
      background-size: 100% 100%;
    }

    ::v-deep {
      .el-carousel {
        height: 307px;
        overflow: hidden;
      }

      .el-carousel__container {
        width: 750px;
        height: 100%;
        margin: auto;
      }
    }

    &__information {
      display: flex;
      justify-content: center;

      &__img {
        width: 221px;
        height: 307px;
        background-size: 100%;
        background-repeat: no-repeatß;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

      }

      &__info {
        margin-left: 32px;
        width: 410px;

        &__title {
          width: 426px;
          border-bottom: 1px solid #C8934E;
          text-align: left;
          padding-bottom: 10px;

          &-name {
            font-size: 36px;
            font-family: Songti SC;
            font-weight: 900;
            color: #333333;
            display: inline-block;
          }

          &-class {
            font-size: 24px;
            font-family: Songti SC;
            font-weight: 400;
            color: #666666;
            margin-left: 23px;
            display: inline-block;
          }
        }

        &__introduce {
          margin-top: 14px;

          &__title {
            width: 126px;
            height: 44px;

            background-size: 100%;
            line-height: 44px;
            font-size: 24px;
            font-family: Songti SC;
            font-weight: 400;
            color: #000;
          }

          p {
            margin: 21px 0 0 0;
            padding: 0;
            font-size: 16px;
            font-family: Songti SC;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            text-align: left;
          }
        }

        &__beGoodat {
          margin-top: 54px;

          &__title {
            width: 126px;
            height: 44px;
            background-image: url('../assets/image/home/3.png');
            background-size: 100%;
            line-height: 44px;
            font-size: 24px;
            font-family: Songti SC;
            font-weight: 400;
            color: #FFFFFF;
          }

          p {
            margin: 21px 0 0 0;
            padding: 0;
            font-size: 16px;
            font-family: Songti SC;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            text-align: left;
          }
        }

      }
    }

    &__Carousel {
      display: flex;
      justify-content: center;
      align-items: center;

      &__left {
        width: 32px;
        height: 58px;
        cursor: pointer;
      }

      &__right {
        width: 32px;
        height: 58px;
        cursor: pointer;
      }

      &__box {
        margin: 61px 41px;
        display: flex;

        &item {
          width: 174px;
          height: 166px;
          position: relative;
          background-image: url('../assets/image/home/2.png');
          background-size: 100%;
          position: relative;
          cursor: pointer;

          &-img {
            -webkit-mask: no-repeat center / contain;
            mask: no-repeat center / contain;
            -webkit-mask-image: url('../assets/image/home/1.png');
            mask-image: url('../assets/image/home/1.png');
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            object-fit: cover;
            transform: translate(-50%, -50%);
          }
        }

        .CarouselActive {
          background-image: url('../assets/image/home/1.png');
          background-size: 100%;
        }
      }
    }
  }

  .course {
    background-color: rgba(172, 151, 115, 0.1);
    padding-top: .1px;

    &__title {
      margin: 40px auto;
      background-image: url('../assets/image/home/组40.png');
      width: 195px;
      height: 70px;
      background-size: 100% 100%;
    }

    &__carousel {
      width: 750px;
      height: 550px;

      ::v-deep {
        .el-carousel {
          width: 100%;
          height: 490px;
          margin: auto;
        }

        .el-carousel__container {
          width: 100%;
          height: 490px;

          .el-carousel__item--card {
            width: 350px;
            background: #FCFBE7;
            filter: brightness(0.5);
            transition: all 0.4s ease;
          }

          .is-active {
            transition: all 0.4s ease;
            filter: brightness(1) !important;
          }

          .el-carousel__arrow {
            background-color: rgba(172, 151, 115, 0);
            height: auto;
            width: auto;

            .el-icon-arrow-left {
              font-weight: 600;
              font-size: 60px !important;

            }

            .el-icon-arrow-right {
              font-weight: 600;
              font-size: 60px !important;
            }
          }

          .el-carousel__arrow--left {
            left: 70px;
          }

          .el-carousel__arrow--right {
            right: 70px;
          }
        }

        .el-icon-arrow-left {}
      }


      &box {
        position: relative;
        height: 160px;

        &__img {
          width: 350px;
          height: 160px;
          object-fit: cover;
        }

        &__time {
          line-height: 48px;
          width: 188px;
          height: 48px;
          background: #C8934E;
          opacity: 0.55;
          font-size: 32px;
          font-family: FZLiShu-S01S;
          font-weight: 400;
          color: #FFFFFF;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }


      &__content {
        text-align: center;
        width: 350px;
        height: 240px;
        padding: 0 16px 0 13px;
        box-sizing: border-box;


        h2 {
          font-size: 28px;
          font-family: Songti SC;
          font-weight: bold;
          color: #221C16;
        }

        p {
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-family: Songti SC;
          font-weight: 400;
          color: #221C16;
        }
      }


    }
  }

  .framework {
    height: 426px;
    width: 100%;

    &__img {
      width: 100%;
      height: 426px;
      object-fit: cover;
    }
  }

  .cooperation {
    background-image: url('../assets/image/home/CultureImg.png');
    background-position: left;
    padding-bottom: 64px;
    padding-top: 40px;
    background-repeat: no-repeat;
    &__title {
      margin: auto;
      background-image: url('../assets/image/home/组40.png');
      width: 195px;
      height: 70px;
      background-size: 100% 100%;
    }
    &__content {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-item {
        background-image: url('../assets/image/home/cooperationItem.png');
        background-size: 100%;
        width: 740px;
        height: 540px;
        overflow: hidden;
        margin-right: 100px;
        padding: 20px 50px 0 50px;
        box-sizing: border-box;

        h2 {
          font-size: 20px;
          font-family: Songti SC;
          font-weight: 400;
          color: #000000;
        }

        p {
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-family: Songti SC;
          font-weight: 400;
          color: #333333;
        }
      }

      &-item:last-child {
        margin-right: 0;
      }
    }
  }

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 61px 0 79px;
    box-sizing: border-box;

    &_line {
      flex: 1;
      height: 2px;
      background: #C8934E;
      border-radius: 1px;
      display: block;
    }

    &__box {
      &__phone {
        img {
          width: 22px;
          height: 22px;
          vertical-align: bottom;
        }

        font-size: 18px;
        font-family: Songti SC;
        font-weight: 400;
        color: #32363C;
        line-height: 22px;

        span {
          margin-top: 12px;
          font-size: 18px;
          font-family: Songti SC;
          font-weight: 400;
          color: #32363C;
          display: block;
          line-height: 13px;
        }
      }

      &__mail {
        margin-top: 45px;

        img {
          width: 22px;
          height: 22px;
          vertical-align: bottom;
        }

        font-size: 18px;
        font-family: Songti SC;
        font-weight: 400;
        color: #32363C;
        line-height: 22px;

        span {
          margin-top: 12px;
          font-size: 18px;
          font-family: Songti SC;
          font-weight: 400;
          color: #32363C;
          display: block;
          line-height: 13px;
        }
      }

      &__from {
        margin-top: 45px;

        img {
          width: 22px;
          height: 22px;
          vertical-align: bottom;
        }

        font-size: 18px;
        font-family: Songti SC;
        font-weight: 400;
        color: #32363C;

        span {
          margin-top: 12px;
          font-size: 18px;
          font-family: Songti SC;
          font-weight: 400;
          color: #32363C;
          display: block;
        }
      }
    }
  }

}
</style>